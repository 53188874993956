<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewCategory">New Category</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
       :responsive="false">
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     
       <!-- <template #userType="{item}">
        <td
          v-if="item.userType == 1">
            Doctor  </td>
          <td v-else>No </td>
       
        
      </template>   -->
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'Department Category', params: {categoryId:item.categoryId,departmentId: item.departmentId, mode: 'view'}}">View</CDropdownItem>
               <!-- <CDropdownItem :to="{name: 'JobTypes', params: {departmentId: item.departmentId, mode: 'view'}}">Job Types </CDropdownItem> -->
                <CDropdownItem :to="{name: 'Item Trackers', params: {categoryId: item.categoryId,departmentId: item.departmentId, mode: 'view'}}">Item Trackers</CDropdownItem>
   
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteDepartment(item.categoryId)"
                    ></ConfirmationModal>

                </CDropdown>

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
<CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  { key: "categoryName", _classes: 'font-weight-bold',   _style: "min-width:200px" },
  
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold',   _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Department Categories",
   components: {
    ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getDepartments() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/categories/"+this.$route.params.departmentId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   
    deleteDepartment(categoryId) {
      console.log("delete called with", categoryId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/category/" +
         categoryId
      )
        .then((response) => response.json())
        .then((data) => {
           this.getDepartments();
          //    this.$nextTick(() => {
          //    this.$refs.vuetable.refresh();
          //  });
          //  window.location.reload();
        });
    },
     cancel() {
    
       this.$router.push({ name: "Departments",params: {subGroupId: this.$route.params.subGroupId}});
    },
    navToNewCategory(){
       this.$router.push({ name: "Department Category", params: {departmentId: this.$route.params.departmentId, mode: "new" } });
    }
  },
  mounted() {
    this.getDepartments();
  },
};
</script>